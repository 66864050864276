"use client";

import { Adapter } from "shared/build/src/services/adapters";
import { BigFloat, SerializedBigFloat } from "shared/build/src/utils/BigFloat";
import { Transform } from "shared/build/src/utils/Transform";
import { getSpecificAdapter } from "../functions";
import { SupportedCollateralId } from "shared/build/src/SupportedCurrencies";
import { LoanCurrencyRatesCollection } from "src/conversion/hooks";
import { useState } from "react";
import { VanirHomePageCalculator } from "../VanirHomePageCalculator";
import { K33HomePageCalculator } from "../K33HomePageCalculator";

type HomePageCalculatorContainerProps = {
  deploymentName?: string;
  adapters: Transform<BigFloat, SerializedBigFloat, Adapter[]>;
  collateralCurrencies: SupportedCollateralId[];
  loanCurrencyCollection: Transform<
    BigFloat,
    SerializedBigFloat,
    LoanCurrencyRatesCollection
  >;
  loanFee: Transform<BigFloat, SerializedBigFloat, BigFloat>;
};

export function HomePageCalculatorContainer(props: HomePageCalculatorContainerProps) {
  const {
    adapters,
    deploymentName,
    collateralCurrencies,
    loanCurrencyCollection,
    loanFee,
  } = BigFloat.deepDeserialize(props);

  const [collateral, setCollateral] = useState(collateralCurrencies[0]);

  const wbtcAdapter = getSpecificAdapter("WBTC-C", adapters);
  const ethAdapter = getSpecificAdapter("ETH-C", adapters);

  const adapter: Adapter = {
    ...(collateral === "wBTC" ? wbtcAdapter : ethAdapter),
    liquidationTreshold: BigFloat.fromNumber(0.765),
  };

  return (
    <div
      id="calculator-container"
      className="flex w-full flex-col items-center"
    >
      <div
        id="calculator-background"
        className={
          "absolute -mt-14 h-[45rem] w-full max-w-xl rounded-lg " +
          (deploymentName === "k33" ? "bg-white" : "bg-calculator-container")
        }
      />
      {deploymentName === "k33" ? (
        <K33HomePageCalculator
          adapter={adapter}
          loanFee={loanFee}
          collateralCurrency={collateral}
          loanCurrencyCollection={loanCurrencyCollection}
          supportedCollateralCurrencies={collateralCurrencies}
          onCollateralChanged={setCollateral}
        />
      ) : (
        <VanirHomePageCalculator
          adapter={adapter}
          collateralCurrency={collateral}
          loanCurrencyCollection={loanCurrencyCollection}
          supportedCollateralCurrencies={collateralCurrencies}
          onCollateralChanged={setCollateral}
        />
      )}
    </div>
  );
}
