"use client";

import { PropsWithChildren, useState } from "react";
import Image from "next/image";
import { Anchor } from "../Anchor";
import { icons } from "../SiteIcons";

/* eslint react/no-unescaped-entities: 0 */

export function K33LandingPageFAQ(props: {
  lowestEthRate: number;
  lowestBtcRate: number;
}) {
  const { lowestBtcRate, lowestEthRate } = props;

  const [open, setOpen] = useState<string | null>(null);

  const icon = icons.info;

  return (
    <div className="flex w-full max-w-[46.875rem] flex-col items-center justify-center gap-3 font-manrope">
      <div className="rounded-3xl bg-[#A54242] py-1 px-5 font-bold text-white">
        Q&A
      </div>
      <div className="mb-7 text-[3.25rem] font-bold">Questions & Answers</div>

      <div className="flex w-full flex-col">
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is a K33 loan?"
        >
          <div>
            A K33 loan helps you get crypto-backed loan that allows you to
            borrow against your cryptocurrencies (known as “Virtual Assets” or
            “Digital Assets”) on the blockchain without selling them. Deposit
            your cryptocurrencies as collateral and take out loans in a USD
            stablecoin or convert it to your local currency (USD, EUR, GBP, CHF,
            NOK, SEK, DKK).
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 loans are nominated and issued in the
              USD stablecoin{" "}
              <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                $DAI
              </Anchor>
              .
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How to create a K33 loan?"
        >
          <div>
            <ol
              type="1"
              className="flex list-inside list-decimal flex-col gap-2"
            >
              <li>
                Go to <Anchor href="/">loans.k33.com</Anchor>{" "}
              </li>
              <li>Launch App </li>
              <li>Sing in with BankID</li>
              <li>
                Transfer or deposit cryptocurrencies to your K33 Loan wallet{" "}
              </li>
              <li>
                Go to {"->"} <b>Create Loan</b>{" "}
              </li>
              <li>Select collateral type (ETH or BTC)</li>
              <li>Select loan type</li>
              <li>
                Input your <b>collateral amount</b>
              </li>
              <li>
                Select your <b>loan currency</b>. For a local currency the loan
                will be issued in{" "}
                <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                  $DAI
                </Anchor>{" "}
                and converted to your selected currency{" "}
              </li>
              <li>
                Select the amount you wish to loan by using the “Adjust your
                loan” slider or entering an amount.
              </li>
              <li>
                Open <b>loan details</b> to see your <b>interest rate</b>,{" "}
                <b>liquidation LTV</b> and <b>loan maturity</b>.
              </li>
              <li>
                Input your bank account if you wish to convert your $DAI into
                local currency and have the loan amount transferred to your
                bank.
              </li>
              <li>
                Read and agree to the Terms and Conditions. Then, click [Create
                Loan].
              </li>
            </ol>
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 loans are nominated and issued in the
              USD stablecoin $DAI.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is a stablecoin?"
        >
          <div>
            A stablecoin is cryptocurrency that has it’s price pegged to a
            specific asset — which is most often, but not always, the United
            States dollar.
            <br />
            <br />
            K33 loans use the stable coin{" "}
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>{" "}
            which is pegged towards $USD.
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is $DAI?"
        >
          <div>
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>{" "}
            is a stablecoin pegged to USD 1:1. Unlike traditional stablecoins
            (USDT, USDC), which are often backed and stabilized by fiat
            currencies held in reserve, $DAI maintains its value through a
            sophisticated system of smart contracts and collateral assets on the
            Ethereum blockchain. $DAI’s backing is overcollateralized which
            means there is always more value in locked collateral assets than
            value of circulating $DAI.
            <br />
            <br />
            $DAI is maintained and regulated by{" "}
            <Anchor href="https://makerdao.com/en/">MakerDAO</Anchor>, a
            decentralized autonomous organization (DAO) composed of the owners
            of its governance token, MKR, who may propose and vote on changes to
            certain parameters in its smart contracts in order to ensure the
            stability of $DAI.
            <br />
            <br />
            <i>
              <b>Important Note</b>: K33 does not warrant or guarantee any part
              of the functionalities offered by MakerDAO.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What is Loan-to-Value (LTV)?"
        >
          <div>
            LTV is the ratio of your <u>Loan</u> to the value of your{" "}
            <u>Collateral</u>.
            <br />
            <br />
            When you borrow a loan, we calculate your loan amount or required
            collateral based on the following formula.
            <br />
            <br />
            LTV = <b>{"(Market Value of Loan Amount)"}</b> ÷{" "}
            <b>{"(Market Value of Collateral)"}</b>
            <br />
            <br />
            When a loan becomes active, we monitor your loan’s LTV based on the
            following formula.
            <br />
            <br />
            LTV = <b>{"(Market Value of Outstanding Balance)"}</b> ÷{" "}
            <b>{"(Market Value of Collateral)"}</b>
            <br />
            <br />
            where, <b>Outstanding Balance</b> ={" "}
            <b>Outstanding Loan Principal </b>+ <b>Outstanding Loan Interest</b>
            <br />
            <br />A composite index price is used to calculate the LTV every 5
            seconds, which incorporates price data from{" "}
            <Anchor href="https://makerdao.com/en/">MakerDAO</Anchor>. Based on
            the price data, we determine the composite index price and use this
            to value your crypto assets for LTV calculation.
            <br />
            <br />
            <br />
            <br />
            <b>Current LTV </b> → Current LTV is the current ratio of your loan
            to the value of your Collateral
            <br />
            <br />
            <b>Max LTV </b>
            → Maximum LTV is not your liquidation point, but is the maximum loan
            to value ratio that your debt can reach as a % of your collateral.
            <br />
            <br />
            <b>Liq LTV </b>→ Liquidation LTV is the debt collateral ratio at
            which your position can will be liquidated. When the LTV ratio is
            higher than the Liq LTV ratio, K33 will partially liquidate your
            collateral assets to adjust your loan position to a healthy LTV →
            {" >"}10% below Max LTV. Please note that liquidation will be
            triggered when the corresponding Liq LTV ratio is reached.
            <br />
            <br />
            <i>Liquidation Fee</i>: In the event of a liquidation, you will be
            charged 5% of the liquidated amount as a liquidation fee and be
            deducted from your collateral.
            <br />
            <br />
            <b>Loan Health</b> → Each of your outstanding loans will have a Loan
            Heath indicator, which reflects the risk level based on your LTV.
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 will notify you when your Loan Health
              changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How does K33 help you manage your Loan?"
        >
          <div>
            Your loan will have a Loan Heath indicator, which reflects the risk
            level based on your LTV.
            <br />
            <br />
            A health status, which is based on your loan’s LTV and the defined
            thresholds, is provided to help you better manage your loan.
            <br />
            <br />
            K33 will notify you on email and SMS when your Loan Health changes
            status.
            <br />
            <br />
            <br />
            <table className="table-fixed border-collapse border border-black">
              <tbody>
                <tr>
                  <th className="border border-black p-2">Loan’s LTV</th>
                  <th className="border border-black p-2">Health Status</th>
                  <th className="border border-black p-2">
                    Restricted Function
                  </th>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    {">"} 25% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      GOOD <div className="h-4 w-4 bg-success" />
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    25% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      FAIR <div className="h-4 w-4 bg-warning" />{" "}
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">
                    15% below liq LTV
                  </td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      POOR <div className="h-4 w-4 bg-danger" />{" "}
                    </div>
                  </td>
                  <td className="border border-black p-2">None</td>
                </tr>
                <tr>
                  <td className=" border border-black p-2">5% below Liq LTV</td>
                  <td className="border border-black p-2">
                    <div className="flex h-full flex-row items-center gap-2">
                      CRITICAL <div className="h-4 w-4 bg-danger" />
                    </div>
                  </td>
                  <td className="border border-black p-2">Borrow</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 will notify you when your Loan Health
              changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="What happens when my loan reaches Liquidation LTV?"
        >
          <div>
            Liquidation LTV is the debt collateral ratio at which your position
            will be liquidated. When the LTV ratio is higher than the Liq LTV
            ratio, K33 will <u>partially liquidate</u> your collateral assets to
            adjust your loan position to a healthy LTV → {">"}10% below Max LTV.
            Please note that liquidation will be triggered when the
            corresponding Liq LTV ratio is reached.
            <br />
            <i>Liquidation Fee</i>: In the event of a liquidation, you will be
            charged 5% of the value of the loaned asset as a liquidation fee and
            be deducted from your collateral.
            <br />
            <br />
            <i>
              {" "}
              <b>Important Note</b>: K33 will notify you when your Loan Health
              changes status.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How do I improve my Loan-to-Value (LTV) Percentage?"
        >
          <div className="flex flex-col gap-2">
            <p>
              You may adjust your loan’s LTV (loan-to-value) in 3 ways: Adjust
              Collateral, Increase Loan, Downpay Loan. An active loan’s LTV
              depends on the outstanding balance of the loan. Please note to
              keep your LTV in the Safe LTV zone for better Loan Health.{" "}
            </p>
            <p>
              To <i>decrease</i> your Loan-to-Value (LTV) Percentage;
            </p>
            <p>
              1. Go to <b>Dashboard</b> → <b>My Loans</b>
            </p>
            <p>
              2. Select <b>Change Collateral</b> → <b>Deposit Collateral</b>
            </p>
            <p>OR</p>
            <p>
              1. Go to <b>Dashboard</b> → <b>My Loans</b>
            </p>
            <p>
              2. Select <b>Repay Loan</b>
            </p>
            <br />
            <br />
            <p>
              To <i>increase</i> your Loan-to-Value (LTV) Percentage;
            </p>
            <p>
              1. Go to <b>Dashboard</b> → <b>My Loans</b>
            </p>
            <p>
              2. Select <b>Change Collateral</b> → <b>Withdraw Collateral</b>
            </p>
            <p>OR</p>
            <p>
              1. Go to <b>Dashboard</b> → <b>My Loans</b>
            </p>
            <p>
              2. Select <b>Increase Loan</b>
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How is my loan interest calculated?"
        >
          <div className="flex flex-col gap-2">
            <p>
              Interest rates are set by the smart contract protocol. It’s a
              variable rate that changes based on the collateral type and market
              conditions.
            </p>
            <p>
              <b>Interest Rate</b> accrues <i>per second</i> based on your
              unpaid loan principal and is compounded on earlier interest
              charges.
            </p>
            <p>
              <b>K33 fee</b> is charged to your outstanding loan upon loan
              creation.
            </p>
            <br />
            <p>The interest rates for standard loans are as follows:</p>
            <table className="table-fixed border-collapse border border-black">
              <tbody>
                <tr>
                  <th className="border border-black">Loan Type</th>
                  <th className="border border-black">Interest rate</th>
                </tr>
                <tr>
                  <td className="border border-black  pl-2">ETH</td>
                  <td className="border border-black pl-2">
                    {lowestEthRate}% APY (past 1yr average 8.15%)
                  </td>
                </tr>
                <tr>
                  <td className="border border-black  pl-2">BTC</td>
                  <td className="border border-black pl-2">
                    {lowestBtcRate}% APY (past 1yr average 9.05%)
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              <i>
                <b>Important Note:</b> Interest rate is subject to change at any
                time.
              </i>
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="Which currencies are available to loan?"
        >
          {" "}
          <p>
            All K33 loans are nominated and issued in USD stablecoin{" "}
            <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
              $DAI
            </Anchor>
            . You can select other currencies such as USD, EUR, GBP, CHF, NOK,
            SEK and DKK and K33 will convert your loaned $DAI to your selected
            currency. Withdraw your loan to your bank account at any time.{" "}
          </p>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="Which cryptocurrencies are accepted as collateral?"
        >
          "K33 accepts ETH and BTC as collateral. We will be continually adding
          to this list!
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How do I repay my loan?"
        >
          {" "}
          <div className="flex flex-col gap-2">
            <p>
              You can repay your loan at any time with any amount during the
              loan term. Your repayments of the outstanding loan (principal +
              interest) must be made in{" "}
              <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                $DAI
              </Anchor>
              . You can deposit $DAI to your K33 Markets wallet or purchase $DAI
              through{" "}
              <Anchor href="https://k33.com/markets">K33 Markets</Anchor>.
            </p>
            <br />
            <p>
              1. Go to <b>Dashboard</b> → <b>My Loans</b>
            </p>
            <p>
              2. Select <b>Pay Loan</b> → <b>Insert Amount</b>
            </p>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How long are the loan terms for a K33 loan?"
        >
          All loans on K33 are up to 12-month in length with automatic renawal
          if both parties agree. You can choose to repay at any time with any
          amount.
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How can I withdraw my loan to my bank account?"
        >
          <div className="flex flex-col gap-2">
            <p>
              You will have the option to withdraw your loan to a bank account
              upon loan creation by selecting → Withdraw to bank account. You
              need to have stated a bank account in your K33 profile.
            </p>
            <br />
            <p>
              You will always have the option to do this after loan creation as
              well by;
            </p>
            <p>
              1. Go to <b>Dashboard</b> → <b>Wallet</b>
            </p>
            <p>
              2. <b>Select currency</b> → <b>Withdraw</b>
            </p>
            <br />
            <br />
            <i>
              <b>Important Note</b>: Your loan is nominated in the USD
              stablecoin{" "}
              <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                $DAI
              </Anchor>
              . When creating or withdrawing a loan in a FIAT currency, K33 will
              convert your $DAI to your selected currency.
            </i>
          </div>
        </K33LandingPageFAQButton>
        <K33LandingPageFAQButton
          open={open}
          setOpen={(open) => setOpen(open)}
          question="How does K33 loans work?"
        >
          <div className="flex flex-col gap-2">
            <p>
              K33 helps you interact with a smart contract on the Ethereum
              blockchain. This smart contract functions like a central bank
              whereas you may deposit cryptocurrencies as collateral and the
              smart contract creates the stablecoin{" "}
              <Anchor href="https://coinmarketcap.com/currencies/multi-collateral-dai/">
                $DAI
              </Anchor>
              . This is known as a Collateralized Debt Position (CDP). The smart
              contract will never create $DAI worth more than 75% of the
              deposited collateral. Here’s how it works;
            </p>
            <br />
            <p>
              1. You create a crypto-backed loan on Ethereum blockchain smart
              contract protocol{" "}
              <Anchor href="https://makerdao.com/en/">MakerDAO</Anchor> through
              K33.
            </p>
            <p>
              2. The smart contract generates $DAI based on your collateral and
              LTV and sends it back to your K33 Markets wallet.
            </p>
            <p>
              3. If you choose to convert your loan to a local currency, K33
              will convert your $DAI to your selected currency through{" "}
              <Anchor href="https://k33.com/">K33</Anchor> and send it to your
              stated bank account.
            </p>
            <p>
              4. When you repay your loan K33 sends your $DAI back to the smart
              contract and your collateral is released and sent back to your K33
              Markets wallet. You can repay your loan at any time with any
              amount during the loan term by paying back the loan amount in
              $DAI. Your repayments of the outstanding loan (principal +
              interest) must be made in $DAI.
            </p>
          </div>
        </K33LandingPageFAQButton>
      </div>
      <div className="mt-2 flex flex-row gap-3 font-manrope text-[2F2D3B]">
        <Image
          alt={icon.alt}
          width={icon.width * 1.5}
          height={icon.height * 1.5}
          src={icon.path}
        />
        <p>
          Didn’t find the answer you are looking for?{" "}
          <a
            className="font-bold text-[#A54242] underline"
            href="mailto:support@vanir.finance"
          >
            Contact our support
          </a>
        </p>
      </div>
    </div>
  );
}

function K33LandingPageFAQButton(
  props: {
    question: string;
    open: string | null;
    setOpen: (open: string | null) => void;
  } & PropsWithChildren
) {
  const open = props.open === props.question;

  return (
    <div id="button-container" className="flex flex-col">
      <button
        onClick={() => props.setOpen(open ? null : props.question)}
        id="question-container"
        className="flex min-h-[5.625rem] w-full cursor-pointer flex-row items-center justify-between gap-3 rounded-md border border-[#E0DFE5] border-opacity-50 py-5 px-7 text-start font-manrope text-2xl font-bold sm:py-0"
      >
        {props.question}
        <Image
          className={open ? "-rotate-90" : "rotate-90"}
          alt={"Arrow down"}
          width="10"
          height="35"
          src={"/images/icons/arrow-black.svg"}
        />
      </button>
      {open && (
        <div id="answer-container" className="p-7">
          {props.children}
        </div>
      )}
    </div>
  );
}
